/* src/App.css */
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #f5f5f5;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  background-color: #343a40;
  color: white;
  padding: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box; /* Ensure padding doesn't cause overflow */
  overflow: hidden; /* Prevent overflow */
  max-width: 100%; /* Ensure it doesn't overflow horizontally */
}

.navbar-title {
  font-size: 1.8rem;
}

.navbar-links {
  display: flex;
  gap: 15px;
  flex-wrap: wrap;  /* Allow items to wrap when necessary */
  justify-content: flex-end;  /* Ensure items align to the right */
}

.navbar-link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
}

.navbar-link:hover {
  text-decoration: underline;
}

.editor-container {
  display: flex;
  flex-direction: column;
  padding: 100px 20px 20px;  /* Ensure content doesn't overlap with navbar */
  max-width: 1200px;
  margin: 0 auto;
}

.editor-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.editor-left {
  flex: 2;
  min-width: 600px;
  margin-bottom: 30px;
}

.editor-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 100%;
}

button {
  background-color: #007bff;
  color: white;
  padding: 12px 24px;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  font-size: 16px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

button.run-btn {
  font-size: 18px;
}

button.copy-btn {
  background-color: #28a745;
  padding: 8px 16px;
}

button.copy-btn:hover {
  background-color: #218838;
}

ul {
  list-style-type: none;
  padding: 0;
}

li.solution-card {
  background-color: #ffffff;
  padding: 15px;
  margin: 10px 0;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
}

li.solution-card:hover {
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

li a {
  font-size: 1.2rem;
  color: #007bff;
}

li a:hover {
  text-decoration: underline;
}

.output-container, .errors-container {
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
}

pre {
  font-size: 14px;
  color: #333;
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 8px;
}

.controls {
  display: flex;
  justify-content: flex-start;
}

.editor-left, .editor-right {
  padding: 20px;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-links {
    flex-direction: column;
    gap: 10px;
    justify-content: flex-start;
    width: 100%;
    margin-top: 10px;
  }

  .editor-wrapper {
    flex-direction: column;
  }

  .editor-left {
    width: 100%;
  }

  .editor-right {
    width: 100%;
  }
}
